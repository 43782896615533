<template>
  <v-main>
    <nav-bar />
    <template slot:top>
      <v-subheader>
        <h1>Logements</h1>
      </v-subheader>
      <v-toolbar flat>
        <v-text-field
          class="pr-2 flex-grow-1"
          v-model="query"
          label="Mot clé"
          @input="search"
        />
        <v-select
          v-model="searchField"
          :items="searchFields"
          class="pl-2 flex-grow-0"
          dense
          solo
          @change="search"
        />
      </v-toolbar>
    </template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="itemsLength"
      :options.sync="options"
      :must-sort="true"
      :footer-props="{ 'items-per-page-options': [50] }"
      dense
    >
      <template v-slot:item.owner="{ item }">
        <v-btn small class="info" @click="editOwner(item.ownerUid)">
          {{ item.owner }}
        </v-btn>
      </template>
      <template v-slot:item.status="{ item }">
        <span :class="item.status == '1 Créé'? 'red--text' : 'green--text'" class="text-no-wrap">{{ item.status }}</span>
      </template>
      <template v-slot:item.published="{ item }">
        <span :class="item.published == 'True'? 'green--text' : 'red--text'" class="text-no-wrap">{{ item.published }}</span>
      </template>
      <template v-slot:item.url="{ item }">
        <v-icon small @click="openItem(item)">
          mdi-link
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item.uid)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="toggleActivated(item)">
          {{ !item.available ? "mdi-eye-off" : "mdi-eye" }}
        </v-icon>
      </template>
    </v-data-table>
  </v-main>
</template>

<script>
import axios from "axios";
import navBar from "../components/navBar.vue";
export default {
  components: {
    navBar,
  },
  data() {
    return {
      headers: [
        { text: "Id", value: "id" },
        { text: "Propriétaire", value: "owner" },
        { text: "Adresse", value: "address"},
        { text: "Ville", value: "locality"},
        { text: "Code Postal", value: "postalCode"},
        { text: "Type", value: "type"},
        { text: "Loyer", value: "rent" },
        { text: "Charges", value: "charges"},
        { text: "Pièce(s)", value: "rooms"},
        { text: "m²", value: "surface"},
        { text: "Statut de l'annonce", value: "status"},
        { text: "Publié", value: "published"},
        { text: "Date de création", value: "creationDate"},
        { text: "Date de Publication", value: "publicationDate" },
        { text: "Url", value: "url"},
        { text: "Actions", value: "actions", sortable: false },
      ],
      searchFields: [
        {
          text: "Dans toutes les colonnes",
          value: "id,owner,address,locality,postalCode,type,status,published,creationDate,publicationDate",
        },
        { text: "Propriétaire", value: "owner" },
        { text: "Adresse", value: "address" },
        { text: "Ville", value: "locality" },
        { text: "Code Postal", value: "postalCode" },
        { text: "Type", value: "type" },
        { text: "Statut de l'annonce", value: "status" },
        { text: "Date de création", value: "creationDate"},
        { text: "Date de Publication", value: "publicationDate" },
        { text: "Publié", value: "published" },
      ],
      searchField: "id,owner,address,locality,postalCode,type,status,published,creationDate,publicationDate",
      items: [],
      itemsLength: 0,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      query: "",
    };
  },

  watch: {
    options: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },

  methods: {
    getItems() {
      axios
        .get(process.env.VUE_APP_APIURL + "/properties", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            offset: (this.options.page - 1) * this.options.itemsPerPage,
            limit: this.options.itemsPerPage,
            sort:
              this.options.sortBy[0] +
              " " +
              (this.options.sortDesc[0] ? "DESC" : "ASC"),
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.items = response.data.map((item) =>
            Object.assign(
              item,
              {
                status: {
                  1: "1 Créé",
                  2: "2 Terminé",
                  3: "3 (archivé)",
                }[item.status],
              },
              {
                published: { true: "True", false: "False" }[
                  item.published
                ],
              },
              { creationDate: new Date(item.creationDate).toLocaleString() },
              {
                publicationDate: new Date(
                  item.publicationDate
                ).toLocaleString(),
              },
               { type: item.typeLabel === 'Appartement' ? '1 -Appartement' : '2 -Maison' },
            )
          );
        });
      axios
        .get(process.env.VUE_APP_APIURL + "/properties", {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            count: 1,
            searchQuery: this.query !== "" ? this.query : undefined,
            searchFields: this.searchField,
          },
        })
        .then((response) => {
          this.itemsLength = eval(response.data[0].count);
        });
    },

    search() {
      this.options.page = 1;
      this.getItems();
    },

    editItem(uid) {
      open(
        this.$router.resolve({ name: "property", params: { propertyUid: uid } })
          .href,
        "_blank"
      );
    },

    editOwner(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    openItem(item) {
      open(
        `${process.env.VUE_APP_BASEURL}/location/${item.locality}-${item.postalCode}/${item.propertyTypeLabel}/${item.rooms}-pieces/${item.uid}/`
      );
    },

    toggleActivated(item) {
      const index = this.items.findIndex((element) => element.uid == item.uid);
      this.items[index].available = !this.items[index].available;
      axios
        .put(
          process.env.VUE_APP_APIURL + "/properties/" + item.uid,
          {
            available:
              this.items[index].available !== null
                ? this.items[index].available
                : undefined,
          },
          {
            headers: {
              "Access-Token": localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            document.location.reload();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>
